.App {
  text-align: left;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


.header{
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  position: relative;
  padding: 10px 0;
}



.logo{
  display: flex;
  align-items: center;
}

.navigation{
  display: flex;
  align-items: center;
}

.back-to-top{
  margin-bottom: 3px;
  right: -25px;
}

.center-footer > div{
  margin-right: 8px;
}


@media (max-width:960px){
  .center-footer{
    /* display: flex; */
    /* justify-content: center;  */
  }
  .center-footer > div{
    margin-right: 10px;
  }
}

@media (max-width:830px){
  .navigation{
    position: absolute;
    width: 100%;
    background: #FFC107;
    flex-wrap: wrap;
    z-index: 100;
    height: auto;
    top: 95px;
    display: none;
  }
  .navigation.open{
    display: flex;
  }
  .navigation .MuiGrid-root.MuiGrid-item{
    display: inline-block;
    width: 100%;
    padding: 10px 0;
    text-align: center;
    color: #fff;
    border-bottom: 3px solid #f9bb07;
  }
}

/*burger*/
.burger {
  width: 35px;
  height: 30px;
  /* margin: 10px 10px; */
  cursor: pointer;
  position: absolute;
  right: 0;
  /* z-index: 1000; */
  top: 25px;
  display: none;
}
.burger span {
  background-color: #FFC107;
  position: absolute;
  border-radius: 2px;
  transition: 0.3s cubic-bezier(0.8, 0.5, 0.2, 1.4);
  width: 100%;
  height: 4px;
  transition-duration: 500ms;
}
.burger span:nth-child(1) {
  top: 0;
  left: 0;
}
.burger span:nth-child(2) {
  top: 13px;
  left: 0;
}
.burger span:nth-child(3) {
  bottom: 0;
  left: 0;
}
@media (max-width: 830px) {
  .burger {
    display: inline-block;
  }
}

.burger:not(.clickburger):hover span:nth-child(1) {
  transform: rotate(-3deg) scaleY(1.1);
}
.burger:not(.clickburger):hover span:nth-child(2) {
  transform: rotate(3deg) scaleY(1.1);
}
.burger:not(.clickburger):hover span:nth-child(3) {
  transform: rotate(-4deg) scaleY(1.1);
}

.clickburger span {
  background-color: #FFC107;
}
.clickburger span:nth-child(1) {
  transform: rotate(45deg);
  top: 13px;
}
.clickburger span:nth-child(2) {
  transform: scale(0.1);
}
.clickburger span:nth-child(3) {
  transform: rotate(-45deg);
  top: 13px;
}

/*# sourceMappingURL=burger.css.map */



@media (max-width:768px){
  .loginInput .MuiFormControl-root {
    width: 100%!important;
  }
}

.loader-holder{
  display: none;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  background: rgba(0,0,0,.5);
  width: 100%;
  height: 100%;
  z-index: 1;
}

.lds-roller {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-roller div {
  animation: lds-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  transform-origin: 40px 40px;
}
.lds-roller div:after {
  content: " ";
  display: block;
  position: absolute;
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background: #fff;
  margin: -4px 0 0 -4px;
}
.lds-roller div:nth-child(1) {
  animation-delay: -0.036s;
}
.lds-roller div:nth-child(1):after {
  top: 63px;
  left: 63px;
}
.lds-roller div:nth-child(2) {
  animation-delay: -0.072s;
}
.lds-roller div:nth-child(2):after {
  top: 68px;
  left: 56px;
}
.lds-roller div:nth-child(3) {
  animation-delay: -0.108s;
}
.lds-roller div:nth-child(3):after {
  top: 71px;
  left: 48px;
}
.lds-roller div:nth-child(4) {
  animation-delay: -0.144s;
}
.lds-roller div:nth-child(4):after {
  top: 72px;
  left: 40px;
}
.lds-roller div:nth-child(5) {
  animation-delay: -0.18s;
}
.lds-roller div:nth-child(5):after {
  top: 71px;
  left: 32px;
}
.lds-roller div:nth-child(6) {
  animation-delay: -0.216s;
}
.lds-roller div:nth-child(6):after {
  top: 68px;
  left: 24px;
}
.lds-roller div:nth-child(7) {
  animation-delay: -0.252s;
}
.lds-roller div:nth-child(7):after {
  top: 63px;
  left: 17px;
}
.lds-roller div:nth-child(8) {
  animation-delay: -0.288s;
}
.lds-roller div:nth-child(8):after {
  top: 56px;
  left: 12px;
}
@keyframes lds-roller {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}


/* footer .MuiSvgIcon-root{
  fill: #FFC107;
} */

footer{
  background-color: rgb(51, 51, 51,0.85)!important;
  margin-top: 10px;
}

.form-group{
  display: flex;
  align-items: center;
}

.form-group #checkbox{
  margin: 0 10px 0 0;
  width: 20px;
  height: 20px;
}

.privacy{
  font-size: 16px;
  cursor: pointer;
}

.privacy-policy-holder{
  position: absolute;
  width: 100%;
  height: 100vh;
  z-index: 10;
  display: none;
  justify-content: center;
  align-items: center;
}

.privacy-policy-holder.open{
  display: flex;
}

.privacy-policy{
  width: 90%;
  max-width: 900px;
  background: #fafafa;
  color: #333;
  font-size: 15px;
  text-align: justify;
  padding: 20px;
  overflow-y: auto;
  max-height: 90%;
  border-radius: 15px;
  box-shadow: 2px 2px 18px 3px rgba(0,0,0,0.5);
}

main{
  min-height: 74vh;
}

.overlay{
  position: absolute;
    width: 100%;
    height: 100%;
    /* background: red; */
    top: 0;
    left: 0;
    /* background: red; */
    z-index: 10;
}

.error{
  display: inline-block;
  width: 100%;
  min-height: 16px;
}

.schollHolder{
  position: relative;
}

.schollOver{
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
}

.header-holder{
  display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.header-holder .profile-header{
  display: flex;
  justify-content: space-between;
  width: 45%;
}

.header-holder .profile-header:last-child{
  justify-content: flex-end;
}

.header-holder .profile-header:first-child{
  justify-content: flex-start;
  position: relative;
}

@media (max-width: 768px){
  .header-holder{
    flex-wrap: wrap;
  }
  .header-holder .profile-header{
    width: 100%;
  }
  .header-holder .profile-header:last-child,
  .header-holder .profile-header:first-child{
    margin: 20px;
    justify-content: center;
  }
}

.buttonOverlay{
  position: relative;
}

@media (max-width: 430px){
  .prof-header{
    padding: 0!important;
  }

  .header-holder .profile-header:last-child,
  .header-holder .profile-header:first-child {
    margin: 10px;
    justify-content: space-between;
}
}

@media (max-width: 410px){
.prof-header{
  padding: 0!important;
}
.profile-menu-text{
  display: none;
}
}

.pass-div{
  display: flex;
  justify-content: space-between;
}

.password-holder{
  width: 48%;
}

@media (max-width: 450px){

  .pass-div{
    flex-wrap: wrap;
  }
  .password-holder{
    width: 100%;
  }
}

.quiz-holder{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
}

.question{
  padding: 2rem;
  border-radius: 1rem;
  background: #72AC4D;
  border: none;
  color: #fff;
  margin: 10px;
  box-sizing: border-box;
}

.answerOptions{
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}



li.answerOption{
  margin: 10px;
  width: 45%;
  border: none;
  padding: 1rem;
  box-sizing: border-box;
  border-radius: 1rem;
}

li.answerOption:first-child{
  background: #F3B186;
}
li.answerOption:nth-child(2){
  background: #FED86F;
}
li.answerOption:nth-child(3){
  background: #94CE58;
}
li.answerOption:nth-child(4){
  background: #BED7ED;
}
.questionCount {
  /* padding: 1.5rem 2.5rem 0; */
  font-size: 22px;
  padding: 10px;
}


.radioCustomLabel{
  font-size: 25px;
  text-align: center;
  padding: 0;
  line-height: auto;
  color: #fff;
}

.radioCustomButton ~ .radioCustomLabel:before{
  display: none;
}

.questions-header{
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.timer-holder{
  font-size: 22px;
}

.answerOption{
    display: flex;
    justify-content: center;
    align-items: center;
}

.minutes.danger{
  color: red;
}
